<template>
<div class="pa-10 div-container">
    <v-form @submit.prevent ref="formRef">
    <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Emergency Contact Information in or out of the U.S.</div></v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Family Name(s)<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.emergency_contact_family_names"
        color="grey"
        :rules="[formRules.required, formRules.englishOnly]"
        height="45"
        placeholder="Family Name(s)"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">First (Given) Name(s)<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.emergency_contact_first_given_names"
        color="grey"
        :rules="[formRules.required, formRules.englishOnly]"
        height="45"
        placeholder="First (Given) Name(s)"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Email Address<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.emergency_contact_email_address"
        color="grey"
        :rules="[formRules.required, formRules.email]"
        height="45"
        placeholder="Email Address"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Country Code + Phone<span class="asterisk">*</span></div>
        <span class="phone-style">
        <vue-tel-input-vuetify
          :valid-characters-only="true"
          v-model="formdata.emergency_contact_phone_number"
          label=""
          color="grey"
          :rules="[formRules.required, valid_ecpn || 'Invalid phone number']"
          height="45"
          outlined
          type="tel"
          placeholder="Enter your phone number"
          @input="onInputECPN"
        />
        </span>
    </v-col>
    </v-row>
  <v-row class="justify-center mt-10" v-show="false">
  <v-btn @click="confirm()" id="confirm_btn" color="grey darken-2" class="mx-1 rounded-0 elevation-0 white--text">save Details</v-btn>
  </v-row>
  </v-form>
</div>
</template>
<script>
  export default {
    props: ['clickedNext', 'currentStep'],
    data: () => ({
    formdata: {
        transit_bool: null,
        us_contact_unknown_bool: null,
        us_contact_name: null,
        us_contact_address_line_1: null,
        us_contact_address_line_2: null,
        us_contact_apartment_number: null,
        us_contact_city: null,
        us_contact_state: null,
        us_contact_phone_number: null,
        us_address_same_as_us_contact_bool: null,
        us_address_line_1: null,
        us_address_line_2: null,
        us_apartment_number: null,
        us_city: null,
        us_state: null,
        emergency_contact_family_names: null,
        emergency_contact_first_given_names: null,
        emergency_contact_email_address: null,
        emergency_contact_phone_number: null,
    },
    valid_ecpn: false,
    formatted_ecpn: null
    }),
    watch: {
    formdata: {
        handler () {
         this.$refs.formRef.resetValidation()
        this.edit();
        },
        deep: true,
      },
      other_names: {
        handler(data) {
            this.formdata.other_names = JSON.parse(JSON.stringify(data))
        },
        deep: true
    },
    clickedNext(val) {
        console.log("clicked-next", val)

        const el = document.querySelector(".error--text");
        if(el){
        this.$vuetify.goTo(".error--text", { offset: 60 })
        }
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    currentStep(val) {
        console.log("current-step", val)
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    menu1(val) {
        val && setTimeout(() => (this.activePicker1 = 'YEAR'))
    },
    },
    created() {
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        this.years = range(currentYear, currentYear - 100, -1); 
    },
    mounted() {
        this.$refs.formRef.resetValidation()
        this.states = this.$store.state.states
        if(this.$store.state.formdata != null){
        let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata));
        this.formdata = JSON.parse(JSON.stringify(formdata.travel || {}));
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
        const el = document.querySelector("#stepper-unique-div");
            el.scrollIntoView({
                behavior: 'smooth'
            })
            return;
        }
    },
    methods: {
        save (date) {
            this.$refs.menu1.save(date)
        },
        confirm(){
            if(this.$refs.formRef.validate()){
            let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata || {}));
            let data = JSON.parse(JSON.stringify(this.formdata))
            data.emergency_contact_phone_number = this.formatted_ecpn;
            formdata.travel = data;
            this.$store.dispatch("setFormdata", formdata);
            this.$emit("can-continue", {value: true});
            }
            else {
                this.$emit("can-continue", {value: false});
                this.$emit('change-next', {nextBtnValue: false});
                // this.$refs.formRef.resetValidation()
            }
        },
        edit(){
            this.$emit("can-continue", {value: false});
            this.$emit('change-next', {nextBtnValue: false});
            setTimeout(() => {
            document.getElementById("confirm_btn").click();
            }, 0);
        },
        onInputECPN(formattedNumber, { number, valid, country }) {
            console.log(formattedNumber, number, valid, country)
            this.valid_ecpn = valid
            this.formatted_ecpn = number.e164
        },
    },
  }
</script>
<style scoped>
.asterisk{
    color: #ed527c;
    font-size: 20px;
    margin-left: 7px;
}
.other_names_style{
    background-color: #f2f2f2;
    border-radius: 4px;
}
</style>