import Vue from 'vue';
const eventHub = new Vue();
import axios from 'axios';
import moment from 'moment';

export default {
    created: function() {
        this.token = localStorage.getItem('token');
    },
    data: function() {
        return {
            token: '',
            apiUrl1: process.env.VUE_APP_SERVER_1,
            apiUrl1_main: process.env.VUE_APP_SERVER_1_MAIN,
            apiUrl2: process.env.VUE_APP_SERVER_2,
            G_DOMAIN: 'usa-esta.eta.it.com',
            G_SUBDOMAIN: 'usa-esta.eta.it.com',
            G_WEB_TITLE: 'USA ESTA',
            G_LEGAL_EMAIL: 'legal@usa-esta.eta.it.com',
            G_LEGAL_EMAIL_TO: 'legal@usa-esta.eta.it.com',
            G_SUPPORT_EMAIL: 'support@usa-esta.eta.it.com',
            G_SUPPORT_EMAIL_TO: 'support@usa-esta.eta.it.com',
            eventHub: eventHub,
            translate_code: null,
            formRules: {
                amountExceeded: function(amount, principal, num_of_months) {
                    if (parseFloat(amount) <= parseFloat(principal)) {
                        if(parseFloat(amount) < parseFloat(principal) && num_of_months > 1){
                            return 'Amount must be equal to the payment amount for multiple months';
                        }
                        else{
                            return true;
                        } 
                    }
                    else {
                        return 'Amount must be less than or equal to the payment amount';
                    }
                },
                numberRequired: function(value, customMessage) {
                    if (!(value <= 0)) {
                        return true;
                    } else {
                        return (customMessage) ? customMessage : 'Should be more than zero';
                    }
                },
                dateNotEqual: function(value1, value2) {
                    if (!value1 || !value2) {
                        return true;
                    } else {
                        return value1 !== value2 || 'Dates should not be equal.';
                    }
                },
                emailEqual: function(value1, value2) {
                    if (!value1 || !value2) {
                        return true;
                    } else {
                        return value1 == value2 || 'Emails inputted are not the same.';
                    }
                },
                noNegativeNumberAllowed: function(value, customMessage) {
                    if (!(value < 0) || value == null || value == "") {
                        return true;
                    } else {
                        return (customMessage) ? customMessage : 'Negative value not allowed';
                    }
                },
                numberRequiredButEmptyAllowed: function(value, customMessage) {
                    if (!(value <= 0) || value == null || value == "") {
                        return true;
                    } else {
                        return (customMessage) ? customMessage : 'Should be more than zero';
                    }
                },
                restrict: function(value, customMessage) {
                    if (!value) {
                        return true;
                    } else {
                        return (customMessage) ? customMessage : 'Invalid country!';
                    }
                },
                checkAnswer: function(choice, answer, followUp) {
                    // console.log(choice, answer, followUp)
                    if (answer == null) {
                        return true;
                    }
                    if (choice == answer) {
                        return true;
                    }
                    else if(choice != answer && followUp > 0 ){
                        return true
                    }
                    else {
                        return ' ';
                    }
                },
                passportExpiry: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    else{
                    const nextMonthPlus7Days = new Date();
                    const date = new Date(value);
                    nextMonthPlus7Days.setMonth(nextMonthPlus7Days.getMonth() + 1);
                    nextMonthPlus7Days.setDate(nextMonthPlus7Days.getDate() + 7);
                    return nextMonthPlus7Days.getTime() < date.getTime() || 'Passport expiry date must atleast 1 month & 7 days or more';
                    }
                },
                noDecimal: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^[1-9]\d{0,2}(\.\d{3})*(,\d+)?$/
                    return pattern.test(value) || 'Decimal is not allowed'
                },
                required: value => !!value || 'This field is required',
                required2: value => !!value || '',
                required3: value => typeof value == "boolean" || 'This field is required',
                eligibility: value => typeof value == "boolean" && value === false,
                certify: value => !!value || '',
                passid: value => {
                    const pattern = /^\d{9}$/
                    return pattern.test(value) || 'PASSID must be 9 digits.'
                },
                englishOnly: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^[a-zA-Z_.,' -]*$/
                    return pattern.test(value) || 'Only English aphabets and some characters are allowed.'
                },
                englishAndNumberOnly: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^[a-z0-9_.,'"!?;:& -]+$/i
                    return pattern.test(value) || 'Only English characters and numbers are allowed.'
                },
                email: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern1 = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/
                    const pattern = /^[a-z0-9](?!.*?[^\na-z0-9]{2}).*?[a-z0-9]$/gmi
                    return (pattern.test(value) && pattern1.test(value)) || 'Email address is not valid.'
                },
                emaildnsc: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    let res = '';
                    if (value.includes('@')) {
                        res = value.substring(value.indexOf("@"), value.length)
                    }
                    return res === '@dnsc.edu.ph' || 'Invalid e-mail.'
                },
                requiredArray: (arr) => {
                    return (Array.isArray(arr)) ? (arr.length > 0) ? true : 'Required' : 'Required';
                },
                zipcode: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^[0-9]{5}(?:-[0-9]{4})?$/
                    return pattern.test(value) || 'Invalid zipcode';
                },
                dashAndUnderscore: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^[a-zA-Z0-9-_]+$/
                    return pattern.test(value) || 'Undercore and Dash are only allowed';
                },
                alphaNumbericunderscoreDash: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^[a-zA-Z0-9-_]+$/
                    return pattern.test(value) || 'Alpha,Numberic,Undercore, & Dash are only allowed';
                },
                password: value => {
                    if (value === null || value == '') {
                        return true;
                    }
                    const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
                    return pattern.test(value) || 'Password should be at least 8 characters, must have 1 special character, must have 1 small letter,1 capital letter, and 1 number';
                },

                confirmPassword: function(a, b) {
                    if (a === null || a == '' || b === null || b == '') {
                        return true;
                    }
                    return (a === b) || 'The passwords are not identical. Please try again'
                },
                isRequiredIfValueNotEmpty: function(value, thisValue) {
                    if (value === null || value == '') {
                        return true;
                    } else {
                        return !!thisValue || 'Required'
                    }
                },
                passportNumEqual: function(value1, value2) {
                    if (!value1 || !value2) {
                        return true;
                    } else {
                        return value1 == value2 || "Passport numbers don't match.";
                    }
                },
            },
            userData: {},
        }
    },
    methods: {
        generate_unique_identifier(length = 12, spec = null) {
            var chars = "";
            if(spec == null) {
                chars = "0123456789qwertyuiopasdfghjklzxcvbnmqwertyuiopasdfghjklzxcvbnm";
            } else if(spec == "numbers") {
                chars = "0123456789";
            } else if(spec == "chars_upper") {
                chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            } else if(spec == "chars_lower") {
                chars = "abcdefghijklmnopqrstuvwxyz";
            } else if(spec == "mix_upper") {
                chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            } else if(spec == "mix_lower") {
                chars = "0123456789abcdefghijklmnopqrstuvwxyz";
            } else {
                chars = "0123456789qwertyuiopasdfghjklzxcvbnmqwertyuiopasdfghjklzxcvbnm";
            }
            var str = "";
            var size = chars.length;
            for (var i = 0; i < length; i++) {
                str += chars.charAt(Math.floor(Math.random() * size));
            }
            return str;
        },
        formatPrice: function(value) {
            const val = (value / 1).toFixed(2).replace(',', '.');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        goToAgoda(){
            window.open("https://www.agoda.com/partners/partnersearch.aspx?pcs=1&cid=1912284&city=318")
        },
        validateToken(value) {
            let regex = /\d\d-[A-Za-z][A-Za-z]-\d\d\d\d\d\d\d\d/i;
            return regex.test(value);
        },
        deleteToken: function() {
            localStorage.removeItem('token');
        },
        logout: function() {
            this.deleteToken();
            location.reload();
            // this.$router.replace("/login");
        },
        closeDialog: function(){
            this.eventHub.$emit('closeDialog', false);
        },
        // setGosquared(event, token, data) {
        //     console.log(event, token, data)
        //     var GoSquared = require('gosquared');
        //     var gosquared = new GoSquared({
        //         api_key: '1G8X8W81GP1V9H7J',
        //         site_token: 'GSN-349734-J'
        //     });
        //     gosquared.trackEvent(event, {}, function(err, resp) {
        //         console.log("event", err, resp)
        //     });
        //     var person = gosquared.createPerson();
        //     person.identify({
        //     id: token,
        //     ...data
        //     }, function(err, resp) {
        //         console.log("identify", err, resp)
        //     });
        // },
        axiosCall: function(url, method, data, headers = {}) {
            let options = {
                method: method,
                url: url,
                data: data,
                headers: headers
            };

            if (method.toLowerCase() === 'get' && data) {
                if (Object.keys(data).length > 0) {
                    options.params = data;
                }
            }

            // if (setToken == undefined || setToken) {
            //     if (this.token) {
            //         options.headers = {
            //             'Authorization': `Bearer ${this.token}`
            //         };
            //     }
            // }


            return axios(options)
                .catch((error) => {
                    console.log(error.response);
                    if (error.response.status == 401) {
                        this.deleteToken();
                        this.$router.push('/');
                    } 
                    else {
                        return error.response;
                    }
                });
        },
        toTitleCase: function(str) {
            return str.replace(
                /\w\S*/g,
                function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },
        toLowerCaseAndDashSpace: function(str) {
            return str.replace(/\s+/g, '-').toLowerCase();
        },
        numberWithCommas: function(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        numberOnly: function(str) {
            return str;
        },
        truncateText: function(text, length, clamp) {
            clamp = clamp || '...';
            var node = document.createElement('div');
            node.innerHTML = text;
            var content = node.textContent;
            return content.length > length ? content.slice(0, length) + clamp : content;
        },
        splitFirstnameLastname: function(fullname) {
            let tempString = fullname.split(' '),
                firstName = tempString[0],
                lastName = tempString[tempString.length - 1];

            return {
                firstName: firstName,
                lastName: lastName
            }
        },
        formatDate(date, format = 'MMMM DD, YYYY') {
            if (date) {
                return moment(date).format(format);
            }
        },
        formatDate3(date, format = 'YYYY-MM-DD') {
            if (date) {
                return moment(date).format(format);
            }
        },
        formatDateTime(date, format = 'MMM. DD, YYYY HH:mm') {
            if (date) {
                return moment(date).format(format);
            }
        },
        formatDate2(date, format = 'D MMM YYYY') {
            if (date) {
                return moment(date).format(format);
            }
        },
        formatDateTime2(date, format = 'D MMM YYYY HH:mm') {
            if (date) {
                return moment(date).format(format);
            }
        },
        getImageFileForPreview: function(file, callback) {
            let reader = new FileReader();

            reader.onload = function(e) {
                callback(e.target.result);
            };

            reader.readAsDataURL(file);
        },
        createObjectReplicaNonReactive: function(data) {
            return JSON.parse(JSON.stringify(data));
        },

        decodeHtml: function(html) {
            var txt = document.createElement("textarea");
            txt.innerHTML = html;
            return txt.value;
        },

        generateRandomCharacters: function(num = 20) {
            let text = "",
                chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (let i = 1; i <= num; i++) {
                text += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return text;
        },
        invalidDate(day, month, year) {
            if (day && month && year) {
                let result = moment(day + '/' + month + '/' + year, "DD/MM/YYYY", true).isValid();
                return !result;
            }
        },
        validateAdditionalList(a,b,c,d,e) {
            if(a == true || b == true || c == true || d == true || e == true) {
                return true;
            }
            else{
                return false;
            }
        },
        decodeName(str){
            try {
                if (typeof str != "string") {
                    return str;
                }
                return decodeURIComponent(decodeURIComponent(decodeURIComponent(str)));
            } catch (error) {
                console.log(error)
            }
            
        },
        formatMoney(amount, currency){
            amount = parseFloat(amount || 0);
            let formattedAmount = amount.toLocaleString('en-US', {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true
            });
        
            return formattedAmount;
        },
        formatMoney2(amount, currency){
            amount = parseFloat(amount || 0);
            let formattedAmount = amount.toLocaleString('en-US', {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
                useGrouping: true
            });
        
            return formattedAmount;
        },
    }

};