<template>
<div class="pa-10 div-container">
    <v-form @submit.prevent ref="formRef">
  <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Eligibility Information</div></v-col>
  </v-row>
  <v-row no-gutters class="mt-4">
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1"><strong>1)</strong>
        Do you have a physical or mental disorder; or are you a drug abuser or addict; or do you currently have any of the following diseases (communicable diseases are specified pursuant to section 361(b) of the Public Health Service Act): Cholera, Diphtheria, Tuberculosis, infectious, Plague, Smallpox, Yellow Fever, Viral Hemorrhagic Fevers, including Ebola, Lassa, Marburg, Crimean-Congo, Severe acute respiratory illnesses capable of transmission to other persons and likely to cause mortality.
        </div>
        <v-radio-group
            v-model="formdata.mental_disorder_bool"
            :rules="[formRules.eligibility]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.mental_disorder_bool">
        <v-alert
        type="error"
        dense
        >
        Based on this answer, you cannot apply for ESTA. You may need a visa or other type of travel document to travel to the United States. Please contact your local US consulate for alternate VISA options.
    </v-alert>
    </v-col>
    <v-col cols="12" class="mb-4"><v-divider></v-divider></v-col>
  </v-row>
  <v-row no-gutters class="mt-4">
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1"><strong>2)</strong>
        Have you ever been arrested or convicted for a crime that resulted in serious damage to property, or serious harm to another person or government authority?
        </div>
        <v-radio-group
            v-model="formdata.been_arrested_bool"
            :rules="[formRules.eligibility]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.been_arrested_bool">
        <v-alert
        type="error"
        dense
        >
        Based on this answer, you cannot apply for ESTA. You may need a visa or other type of travel document to travel to the United States. Please contact your local US consulate for alternate VISA options.
    </v-alert>
    </v-col>
    <v-col cols="12" class="mb-4"><v-divider></v-divider></v-col>
  </v-row>
  <v-row no-gutters class="mt-4">
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1"><strong>3)</strong>
        Have you ever violated any law related to possessing, using, or distributing illegal drugs?
        </div>
        <v-radio-group
            v-model="formdata.drugs_bool"
            :rules="[formRules.eligibility]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.drugs_bool">
        <v-alert
        type="error"
        dense
        >
        Based on this answer, you cannot apply for ESTA. You may need a visa or other type of travel document to travel to the United States. Please contact your local US consulate for alternate VISA options.
    </v-alert>
    </v-col>
    <v-col cols="12" class="mb-4"><v-divider></v-divider></v-col>
  </v-row>
  <v-row no-gutters class="mt-4">
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1"><strong>4)</strong>
        Do you seek to engage in or have you ever engaged in terrorist activities, espionage, sabotage, or genocide?
        </div>
        <v-radio-group
            v-model="formdata.terrorist_bool"
            :rules="[formRules.eligibility]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.terrorist_bool">
        <v-alert
        type="error"
        dense
        >
        Based on this answer, you cannot apply for ESTA. You may need a visa or other type of travel document to travel to the United States. Please contact your local US consulate for alternate VISA options.
    </v-alert>
    </v-col>
    <v-col cols="12" class="mb-4"><v-divider></v-divider></v-col>
  </v-row>
  <v-row no-gutters class="mt-4">
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1"><strong>5)</strong>
        Have you ever committed fraud or misrepresented yourself or others to obtain, or assist others to obtain, a visa or entry into the United States?
        </div>
        <v-radio-group
            v-model="formdata.obtain_visa_bool"
            :rules="[formRules.eligibility]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.obtain_visa_bool">
        <v-alert
        type="error"
        dense
        >
        Based on this answer, you cannot apply for ESTA. You may need a visa or other type of travel document to travel to the United States. Please contact your local US consulate for alternate VISA options.
    </v-alert>
    </v-col>
    <v-col cols="12" class="mb-4"><v-divider></v-divider></v-col>
  </v-row>
  <v-row no-gutters class="mt-4">
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1"><strong>6)</strong>
        Are you currently seeking employment in the United States or were you previously employed in the United States without prior permission from the U.S. government?
        </div>
        <v-radio-group
            v-model="formdata.usa_employment_bool"
            :rules="[formRules.eligibility]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.usa_employment_bool">
        <v-alert
        type="error"
        dense
        >
        Based on this answer, you cannot apply for ESTA. You may need a visa or other type of travel document to travel to the United States. Please contact your local US consulate for alternate VISA options.
    </v-alert>
    </v-col>
    <v-col cols="12" class="mb-4"><v-divider></v-divider></v-col>
  </v-row>
  <v-row no-gutters class="mt-4">
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1"><strong>7)</strong>
        Have you ever been denied a U.S. visa you applied for with your current or previous passport, or have you ever been refused admission to the United States or withdrawn your application for admission at a U.S. port of entry?
        </div>
        <v-radio-group
            v-model="formdata.rejected_visa_bool"
            :rules="[formRules.eligibility]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.rejected_visa_bool">
        <v-alert
        type="error"
        dense
        >
        Based on this answer, you cannot apply for ESTA. You may need a visa or other type of travel document to travel to the United States. Please contact your local US consulate for alternate VISA options.
    </v-alert>
    </v-col>
    <v-col cols="12" class="mb-4"><v-divider></v-divider></v-col>
  </v-row>
  <v-row no-gutters class="mt-4">
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1"><strong>8)</strong>
        Have you ever stayed in the United States longer than the admission period granted to you by the U.S. government?
        </div>
        <v-radio-group
            v-model="formdata.stay_longer_bool"
            :rules="[formRules.eligibility]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.stay_longer_bool">
        <v-alert
        type="error"
        dense
        >
        Based on this answer, you cannot apply for ESTA. You may need a visa or other type of travel document to travel to the United States. Please contact your local US consulate for alternate VISA options.
    </v-alert>
    </v-col>
    <v-col cols="12" class="mb-4"><v-divider></v-divider></v-col>
  </v-row>
  <v-row no-gutters class="mt-4">
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1"><strong>9)</strong>
        Have you traveled to, or been present in Iran, Iraq, Libya, North Korea, Somalia, Sudan, Syria or Yemen on or after March 1, 2011?
        </div>
        <v-radio-group
            v-model="formdata.been_in_forbidden_countries_bool"
            :rules="[formRules.eligibility]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.been_in_forbidden_countries_bool">
        <v-alert
        type="error"
        dense
        >
        Based on this answer, you cannot apply for ESTA. You may need a visa or other type of travel document to travel to the United States. Please contact your local US consulate for alternate VISA options.
    </v-alert>
    </v-col>
    <v-col cols="12" class="mb-4"><v-divider></v-divider></v-col>
  </v-row>
  <v-row no-gutters class="mt-4">
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1"><strong>10)</strong>
        Have you ever traveled to, or been present in Cuba on or after January 12, 2021?
        </div>
        <v-radio-group
            v-model="formdata.been_in_cuba_bool"
            :rules="[formRules.eligibility]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.been_in_cuba_bool">
        <v-alert
        type="error"
        dense
        >
        Based on this answer, you cannot apply for ESTA. You may need a visa or other type of travel document to travel to the United States. Please contact your local US consulate for alternate VISA options.
    </v-alert>
    </v-col>
    <v-col cols="12" class="mb-4"><v-divider></v-divider></v-col>
  </v-row>
  <v-row class="justify-center mt-10" v-show="false">
  <v-btn @click="reset()" outlined color="grey darken-2" class="mx-1 rounded-0 elevation-0">reset</v-btn>
  <v-btn @click="confirm()" id="confirm_btn" color="grey darken-2" class="mx-1 rounded-0 elevation-0 white--text">save Details</v-btn>
  </v-row>
  </v-form>
</div>
</template>
<script>
  export default {
    props: ['clickedNext', 'currentStep'],
    data: () => ({
    formdata: {
        mental_disorder_bool: false,
        been_arrested_bool: false,
        drugs_bool: false,
        terrorist_bool: false,
        obtain_visa_bool: false,
        usa_employment_bool: false,
        rejected_visa_bool: false,
        stay_longer_bool: false,
        been_in_forbidden_countries_bool: false,
        been_in_cuba_bool: false,
    },
    // questions: [
    //     {id: 1, question: 'Do you have a physical or mental disorder; or are you a drug abuser or addict; or do you currently have any of the following diseases (communicable diseases are specified pursuant to section 361(b) of the Public Health Service Act): Cholera, Diphtheria, Tuberculosis, infectious, Plague, Smallpox, Yellow Fever, Viral Hemorrhagic Fevers, including Ebola, Lassa, Marburg, Crimean-Congo, Severe acute respiratory illnesses capable of transmission to other persons and likely to cause mortality.', question_min: 'mental_disorder_bool'},
    //     {id: 2, question: 'Have you ever been arrested or convicted for a crime that resulted in serious damage to property, or serious harm to another person or government authority?', question_min: 'been_arrested_bool'},
    //     {id: 3, question: 'Have you ever violated any law related to possessing, using, or distributing illegal drugs?', question_min: 'drugs_bool'},
    //     {id: 4, question: 'Do you seek to engage in or have you ever engaged in terrorist activities, espionage, sabotage, or genocide?', question_min: 'terrorist_bool'},
    //     {id: 5, question: 'Have you ever committed fraud or misrepresented yourself or others to obtain, or assist others to obtain, a visa or entry into the United States?', question_min: 'obtain_visa_bool'},
    //     {id: 6, question: 'Are you currently seeking employment in the United States or were you previously employed in the United States without prior permission from the U.S. government?', question_min: 'usa_employment_bool'},
    //     {id: 7, question: 'Have you ever been denied a U.S. visa you applied for with your current or previous passport, or have you ever been refused admission to the United States or withdrawn your application for admission at a U.S. port of entry?', question_min: 'rejected_visa_bool'},
    //     {id: 8, question: 'Have you ever stayed in the United States longer than the admission period granted to you by the U.S. government?', question_min: 'stay_longer_bool'},
    //     {id: 9, question: 'Have you traveled to, or been present in Iran, Iraq, Libya, North Korea, Somalia, Sudan, Syria or Yemen on or after March 1, 2011?', question_min: 'been_in_forbidden_countries_bool'},
    //     {id: 10, question: 'Have you ever traveled to, or been present in Cuba on or after January 12, 2021?', question_min: 'been_in_cuba_bool'},
    // ]
    }),
    watch: {
    formdata: {
        handler () {
         this.$refs.formRef.resetValidation()
        this.edit();
        },
        deep: true,
      },
    clickedNext(val) {
        console.log("clicked-next", val)

        const el = document.querySelector(".error--text");
        if(el){
        this.$vuetify.goTo(".error--text", { offset: 60 })
        }
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    currentStep(val) {
        console.log("current-step", val)
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    },
    mounted() {
        this.$refs.formRef.resetValidation()
        if(this.$store.state.formdata != null){
        let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata));
        if(formdata.eligibility){
            this.formdata = JSON.parse(JSON.stringify(formdata.eligibility));
        }
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
        const el = document.querySelector("#stepper-unique-div");
            el.scrollIntoView({
                behavior: 'smooth'
            })
            return;
        }
    },
    methods: {
        confirm(){
            if(this.$refs.formRef.validate()){
            let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata || {}));
            formdata.eligibility = JSON.parse(JSON.stringify(this.formdata));
            this.$store.dispatch("setFormdata", formdata);
            this.$emit("can-continue", {value: true});
            }
            else {
                this.$emit("can-continue", {value: false});
                this.$emit('change-next', {nextBtnValue: false});
                // this.$refs.formRef.resetValidation()
            }
        },
        edit(){
            this.$emit("can-continue", {value: false});
            this.$emit('change-next', {nextBtnValue: false});
            setTimeout(() => {
            document.getElementById("confirm_btn").click();
            }, 0);
        },
    },
  }
</script>
<style scoped>
.asterisk{
    color: #ed527c;
    font-size: 20px;
    margin-left: 7px;
}
.other_names_style{
    background-color: #f2f2f2;
    border-radius: 4px;
}
</style>