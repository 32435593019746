<template>
<div class="pa-10 div-container">
    <v-form @submit.prevent ref="formRef">
  <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Passport Information</div></v-col>
    <!-- <v-col cols="12"><v-divider></v-divider></v-col> -->
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Country of Citizenship<span class="asterisk">*</span></div>
        <v-autocomplete
            v-model="formdata.country_of_citizenship"
            @change="changeCitizenship()"
            color="grey"
            :rules="[formRules.required]"
            height="45"
            outlined
            item-text="country"
            item-value="code"
            :items="citizenship_countries"
            item-color="#00a34e"
            hide-details="auto"
        ></v-autocomplete>
        <div class="hint-text">
            Please select the country of citizenship, as displayed on your passport or travel document.
        </div>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Issuing Country<span class="asterisk">*</span></div>
        <v-autocomplete
            v-model="formdata.issuing_country"
            color="grey"
            :rules="[formRules.required]"
            height="45"
            outlined
            item-text="country"
            item-value="code"
            :items="citizenship_countries"
            item-color="#00a34e"
            hide-details="true"
        ></v-autocomplete>
        <div class="hint-text">
            Select the country that issued your passport. In most cases this is the same as your country of citizenship.
        </div>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Passport Number<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.passport_number"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        outlined
        hide-details="auto"
        ></v-text-field>
        <div class="hint-text">
            Enter the passport number exactly as it appears on the passport information page.
        </div>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Passport Number Confirm<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.passport_number_confirm"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly,  formRules.passportNumEqual(formdata.passport_number, formdata.passport_number_confirm)]"
        height="45"
        outlined
        hide-details="auto"
        ></v-text-field>
        <div class="hint-text">
            Please re-enter the passport number.
        </div>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-5 text-subtitle-1">Passport Issued Date<span class="asterisk">*</span></div>
        <v-row>
        <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
                v-model="formdata.passport_issuance_day"
                color="grey"
                :rules="[formRules.required, !invalidDate(formdata.passport_issuance_day, formdata.passport_issuance_month,formdata.passport_issuance_year)]"
                height="45"
                outlined
                placeholder="Day"
                :items="days"
                item-color="#00a34e"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
            <v-autocomplete
                v-model="formdata.passport_issuance_month"
                color="grey"
                :rules="[formRules.required, !invalidDate(formdata.passport_issuance_day, formdata.passport_issuance_month,formdata.passport_issuance_year)]"
                height="45"
                outlined
                placeholder="Month"
                item-text="desc"
                item-value="id"
                :items="months"
                item-color="#00a34e"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
                v-model="formdata.passport_issuance_year"
                color="grey"
                :rules="[formRules.required, !invalidDate(formdata.passport_issuance_day, formdata.passport_issuance_month,formdata.passport_issuance_year)]"
                height="45"
                outlined
                placeholder="Year"
                :items="years"
                item-color="#00a34e"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="pt-0 mt-n7" v-if="invalidDate(formdata.passport_issuance_day, formdata.passport_issuance_month,formdata.passport_issuance_year)">
            <span class="date-error">Passport issued date is invalid!</span>
        </v-col>
        </v-row>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-5 text-subtitle-1">Passport Expiration Date<span class="asterisk">*</span></div>
        <v-row>
        <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
                v-model="formdata.passport_expiration_day"
                color="grey"
                :rules="[formRules.required, !invalidDate(formdata.passport_expiration_day, formdata.passport_expiration_month,formdata.passport_expiration_year)]"
                height="45"
                outlined
                placeholder="Day"
                :items="days"
                item-color="#00a34e"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
            <v-autocomplete
                v-model="formdata.passport_expiration_month"
                color="grey"
                :rules="[formRules.required, !invalidDate(formdata.passport_expiration_day, formdata.passport_expiration_month,formdata.passport_expiration_year)]"
                height="45"
                outlined
                placeholder="Month"
                item-text="desc"
                item-value="id"
                :items="months"
                item-color="#00a34e"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
                v-model="formdata.passport_expiration_year"
                color="grey"
                :rules="[formRules.required, !invalidDate(formdata.passport_expiration_day, formdata.passport_expiration_month,formdata.passport_expiration_year)]"
                height="45"
                outlined
                placeholder="Year"
                :items="years2"
                item-color="#00a34e"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="pt-0 mt-n7" v-if="invalidDate(formdata.passport_expiration_day, formdata.passport_expiration_month,formdata.passport_expiration_year)">
            <span class="date-error">Passport expiration date is invalid!</span>
        </v-col>
        </v-row>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">National Identification Number<span class="asterisk">*</span></div>
        <v-text-field
        v-if="enableNationalId()"
        color="grey"
        height="45"
        outlined
        disabled
        hide-details="auto"
        ></v-text-field>
        <v-text-field
        v-else
        v-model="formdata.national_identification_number"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        color="grey"
        height="45"
        outlined
        hide-details="auto"
        ></v-text-field>
        <div class="hint-text">
            Please find the national identification number on an ID issued by your country.
        </div>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Personal Identification Number<span class="asterisk">*</span></div>
        <v-text-field
        v-if="enablePersonalId()"
        color="grey"
        height="45"
        outlined
        disabled
        hide-details="auto"
        ></v-text-field>
        <v-text-field
        v-else
        v-model="formdata.personal_identification_number"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        outlined
        hide-details="auto"
        ></v-text-field>
        <div class="hint-text">
            Please specify your PIN, if applicable
        </div>
    </v-col>
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Gender<span class="asterisk">*</span></div>
        <v-radio-group
            v-model="formdata.gender"
            :rules="[formRules.required]"
            row
            class="mt-0"
            >
            <v-radio
                label="Male"
                value="M"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Female"
                value="F"
                color="#00a34e"
            ></v-radio>
            </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Have you ever been issued a passport or national identity card for travel by any other country?<span class="asterisk">*</span></div>
        <v-radio-group
            v-model="formdata.has_other_issuing_country_bool"
            @change="moreNI(formdata.has_other_issuing_country_bool)"
            :rules="[formRules.required3]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
            </v-radio-group>
    </v-col>
    <v-col cols="12" class="other_names_style" v-if="has_other_issuing_country.length > 0">
        <v-row no-gutters v-for="(ni, index) in has_other_issuing_country" :key="index">
            <v-col cols="12" class="pa-0 other-toolber">
                <v-toolbar flat max-height="50" class="pa-0 ma-0" color="transparent">
                <span class="text-h6">Other Issuing Country {{index + 1}}</span>
                <v-spacer></v-spacer>
                <v-btn icon color="#00a34e" @click="removeNI(index)"><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-toolbar>
            </v-col>
            <v-col cols="12" class="pt-0 mb-4"><v-divider></v-divider></v-col>
            <v-col cols="12" md="6" class="px-2">
                <div class="px-0 pb-2 text-subtitle-1">Issuing Country<span class="asterisk">*</span></div>
                <v-autocomplete
                    v-model="ni.has_other_issuing_country_country"
                    color="grey"
                    :rules="[formRules.required]"
                    height="45"
                    outlined
                    item-text="country"
                    item-value="code"
                    :items="countries"
                    item-color="#00a34e"
                    placeholder="Select Country"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="px-2">
                <div class="px-0 pb-2 text-subtitle-1">Document Number<span class="asterisk">*</span></div>
                <v-text-field
                v-model="ni.has_other_issuing_country_document_number"
                color="grey"
                :rules="[formRules.required, formRules.englishAndNumberOnly]"
                height="45"
                outlined
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="px-2">
                <div class="px-0 pb-2 text-subtitle-1">Document Type<span class="asterisk">*</span></div>
                <v-autocomplete
                    v-model="ni.has_other_issuing_country_document_type"
                    color="grey"
                    :rules="[formRules.required]"
                    height="45"
                    outlined
                    item-text="description"
                    item-value="val"
                    :items="documentTypes1"
                    item-color="#00a34e"
                    placeholder="Document Type"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="px-2">
                <div class="px-0 pb-2 text-subtitle-1">Expiration Year<span class="asterisk">*</span></div>
                <v-autocomplete
                v-model="ni.has_other_issuing_country_expiration_year"
                color="grey"
                :rules="[formRules.required]"
                height="45"
                outlined
                placeholder="Year"
                :items="yearsAll"
                item-color="#00a34e"
            ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
        <v-col cols="12" class="pb-8">
            <v-btn color="#00a34e" dark class="elevation-0 rounded-md" @click="addNI()">Add Other Issuing Country</v-btn>
        </v-col>
        </v-row> 
    </v-col>
    <v-col cols="12" class="pt-3"><v-divider></v-divider></v-col>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Other Citizenship/Nationality</div></v-col>
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Are you now a citizen or national of any other country?<span class="asterisk">*</span></div>
        <v-radio-group
            v-model="formdata.has_other_citizenship_country_bool"
            @change="moreOC_now(formdata.has_other_citizenship_country_bool)"
            :rules="[formRules.required3]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
            </v-radio-group>
    </v-col>
    <v-col cols="12" class="other_names_style" v-if="has_other_citizenship_country.length > 0">
        <v-row no-gutters v-for="(oc, index) in has_other_citizenship_country" :key="index">
            <v-col cols="12" class="pa-0 other-toolber">
                <v-toolbar flat max-height="50" class="pa-0 ma-0" color="transparent">
                <span class="text-h6">Other Citizenship Country {{index + 1}}</span>
                <v-spacer></v-spacer>
                <v-btn icon color="#00a34e" @click="removeOC_now(index)"><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-toolbar>
            </v-col>
            <v-col cols="12" class="pt-0 mb-4"><v-divider></v-divider></v-col>
            <v-col cols="12" md="6" class="px-2">
                <div class="px-0 pb-2 text-subtitle-1">Country of Citizenship / Nationality<span class="asterisk">*</span></div>
                <v-autocomplete
                    v-model="oc.has_other_citizenship_country_country"
                    color="grey"
                    :rules="[formRules.required]"
                    height="45"
                    outlined
                    item-text="country"
                    item-value="code"
                    :items="countries"
                    item-color="#00a34e"
                    placeholder="Select Country"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="px-2">
                <div class="px-0 pb-2 text-subtitle-1">Document Type<span class="asterisk">*</span></div>
                <v-autocomplete
                    v-model="oc.has_other_citizenship_country_acquired"
                    color="grey"
                    :rules="[formRules.required]"
                    height="45"
                    outlined
                    item-text="description"
                    item-value="val"
                    :items="documentTypes2"
                    item-color="#00a34e"
                    placeholder="Document Type"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
        <v-col cols="12" class="pb-8">
            <v-btn color="#00a34e" dark class="elevation-0 rounded-md" @click="addOC_now()">Add Other Citizenship Country</v-btn>
        </v-col>
        </v-row> 
    </v-col>
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Have you ever been a citizen or national of any other country?<span class="asterisk">*</span></div>
        <v-radio-group
            v-model="formdata.has_other_citizenship_country_before_bool"
            @change="moreOC_been(formdata.has_other_citizenship_country_before_bool)"
            :rules="[formRules.required3]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
            </v-radio-group>
    </v-col>
    <v-col cols="12" class="other_names_style" v-if="has_other_citizenship_country_before.length > 0">
        <v-row no-gutters v-for="(oc, index) in has_other_citizenship_country_before" :key="index">
            <v-col cols="12" class="pa-0 other-toolber">
                <v-toolbar flat max-height="50" class="pa-0 ma-0" color="transparent">
                <span class="text-h6">Other Citizenship Country Before {{index + 1}}</span>
                <v-spacer></v-spacer>
                <v-btn icon color="#00a34e" @click="removeOC_been(index)"><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-toolbar>
            </v-col>
            <v-col cols="12" class="pt-0 mb-4"><v-divider></v-divider></v-col>
            <v-col cols="12" class="px-2">
                <div class="px-0 pb-2 text-subtitle-1">Country of Citizenship / Nationality<span class="asterisk">*</span></div>
                <v-autocomplete
                    v-model="oc.has_other_citizenship_country_before_country"
                    color="grey"
                    :rules="[formRules.required]"
                    height="45"
                    outlined
                    item-text="country"
                    item-value="code"
                    :items="countries"
                    item-color="#00a34e"
                    placeholder="Select Country"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="px-2">
                <div class="px-0 pb-5 text-subtitle-1">From<span class="asterisk">*</span></div>
                <v-row>
                <v-col cols="12" md="3" class="py-0">
                    <v-autocomplete
                        v-model="oc.has_other_citizenship_country_before_from_day"
                        color="grey"
                        :rules="[formRules.required, !invalidDate(oc.has_other_citizenship_country_before_from_day, oc.has_other_citizenship_country_before_from_month, oc.has_other_citizenship_country_before_from_year)]"
                        height="45"
                        outlined
                        placeholder="Day"
                        :items="days"
                        item-color="#00a34e"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <v-autocomplete
                        v-model="oc.has_other_citizenship_country_before_from_month"
                        color="grey"
                        :rules="[formRules.required, !invalidDate(oc.has_other_citizenship_country_before_from_day, oc.has_other_citizenship_country_before_from_month, oc.has_other_citizenship_country_before_from_year)]"
                        height="45"
                        outlined
                        placeholder="Month"
                        item-text="desc"
                        item-value="id"
                        :items="months"
                        item-color="#00a34e"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                    <v-autocomplete
                        v-model="oc.has_other_citizenship_country_before_from_year"
                        color="grey"
                        :rules="[formRules.required, !invalidDate(oc.has_other_citizenship_country_before_from_day, oc.has_other_citizenship_country_before_from_month, oc.has_other_citizenship_country_before_from_year)]"
                        height="45"
                        outlined
                        placeholder="Year"
                        :items="years"
                        item-color="#00a34e"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-0 mt-n7" v-if="invalidDate(oc.has_other_citizenship_country_before_from_day, oc.has_other_citizenship_country_before_from_month, oc.has_other_citizenship_country_before_from_year)">
                    <span class="date-error">Citizenship before (from) date is invalid!</span>
                </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" class="px-2">
                <div class="px-0 pb-5 text-subtitle-1">To<span class="asterisk">*</span></div>
                <v-row>
                <v-col cols="12" md="3" class="py-0">
                    <v-autocomplete
                        v-model="oc.has_other_citizenship_country_before_to_day"
                        color="grey"
                        :rules="[formRules.required, !invalidDate(oc.has_other_citizenship_country_before_to_day, oc.has_other_citizenship_country_before_to_month, oc.has_other_citizenship_country_before_to_year)]"
                        height="45"
                        outlined
                        placeholder="Day"
                        :items="days"
                        item-color="#00a34e"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <v-autocomplete
                        v-model="oc.has_other_citizenship_country_before_to_month"
                        color="grey"
                        :rules="[formRules.required, !invalidDate(oc.has_other_citizenship_country_before_to_day, oc.has_other_citizenship_country_before_to_month, oc.has_other_citizenship_country_before_to_year)]"
                        height="45"
                        outlined
                        placeholder="Month"
                        item-text="desc"
                        item-value="id"
                        :items="months"
                        item-color="#00a34e"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                    <v-autocomplete
                        v-model="oc.has_other_citizenship_country_before_to_year"
                        color="grey"
                        :rules="[formRules.required, !invalidDate(oc.has_other_citizenship_country_before_to_day, oc.has_other_citizenship_country_before_to_month, oc.has_other_citizenship_country_before_to_year)]"
                        height="45"
                        outlined
                        placeholder="Year"
                        :items="years"
                        item-color="#00a34e"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-0 mt-n7" v-if="invalidDate(oc.has_other_citizenship_country_before_to_day, oc.has_other_citizenship_country_before_to_month, oc.has_other_citizenship_country_before_to_year)">
                    <span class="date-error">Citizenship before (to) date is invalid!</span>
                </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
        <v-col cols="12" class="pb-8">
            <v-btn color="#00a34e" dark class="elevation-0 rounded-md" @click="addOC_been()">Add Other Citizenship Before</v-btn>
        </v-col>
        </v-row> 
    </v-col>
    <v-col cols="12" v-if="enable_additional">
        <div class="px-0 pb-2 text-subtitle-1">You have indicated that you are not a citizen or national of your country of birth. From the list below, please select ALL statements that apply with respect to your country of birth: <span class="asterisk">*</span></div>
        <v-checkbox v-model="formdata.did_not_acquire_citizenship_bool" color="#00a34e" hide-details>
        <template v-slot:label>
            Did not acquire citizenship at birth or have never held citizenship in birth country
        </template>
      </v-checkbox>
      <v-checkbox v-model="formdata.renounced_citizenship_bool" color="#00a34e" hide-details>
        <template v-slot:label>
            Renounced citizenship of birth country
        </template>
      </v-checkbox>
      <v-checkbox v-model="formdata.have_not_lived_birth_country_in_5_years_bool" color="#00a34e" hide-details>
        <template v-slot:label>
            Have not lived or resided in birth country within the past five years
        </template>
      </v-checkbox>
      <v-checkbox v-model="formdata.have_not_held_passport_in_past_5_years_bool" color="#00a34e" hide-details>
        <template v-slot:label>
            Have not held a passport or national identity card from birth country within the past five years
        </template>
      </v-checkbox>
      <div class="d-flex">
        <v-checkbox v-model="formdata.other_bool" color="#00a34e" hide-details>
            <template v-slot:label>
                Other
            </template>
        </v-checkbox>
        <v-text-field
        v-if="formdata.other_bool == true"
        v-model="formdata.other_text"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="30"
        class="mx-3"
        style="max-width: 75%"
        ></v-text-field>
      </div>
    </v-col>
    <v-col cols="12" class="pt-0" v-if="enable_additional && !validateAdditionalList(formdata.did_not_acquire_citizenship_bool, formdata.renounced_citizenship_bool, formdata.have_not_lived_birth_country_in_5_years_bool, formdata.have_not_held_passport_in_past_5_years_bool, formdata.other_bool)">
        <span class="list-error">Please select atleast one on the list</span>
    </v-col>
    <v-col cols="12" class="pt-3"><v-divider></v-divider></v-col>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">GE Membership</div></v-col>
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Are you a member of the CBP Global Entry Program?<span class="asterisk">*</span></div>
        <v-radio-group
            v-model="formdata.has_ge_passid_bool"
            :rules="[formRules.required3]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
            </v-radio-group>
    </v-col>
    <v-col cols="12" md="6" class="py-0" v-if="formdata.has_ge_passid_bool">
        <div class="px-0 pb-2 text-subtitle-1">PASSID / Membership Number<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.ge_passid"
        color="grey"
        :rules="[formRules.required, formRules.passid]"
        height="45"
        outlined
        hide-details="auto"
        ></v-text-field>
        <div class="hint-text">
            Please fill in your CBP Global Entry Program PASSID or Membership Number.
        </div>
    </v-col>
    <v-col cols="12" class="pt-3"><v-divider></v-divider></v-col>
  </v-row>
  <v-row class="justify-center mt-10" v-show="false">
  <v-btn @click="reset()" outlined color="grey darken-2" class="mx-1 rounded-0 elevation-0">reset</v-btn>
  <v-btn @click="confirm()" id="confirm_btn" color="grey darken-2" class="mx-1 rounded-0 elevation-0 white--text">save Details</v-btn>
  </v-row>
  </v-form>
</div>
</template>
<script>
  export default {
    props: ['clickedNext', 'currentStep'],
    data: () => ({
    formdata: {
        country_of_citizenship: null,
        issuing_country: null,
        passport_number: null,
        passport_number_confirm: null,
        passport_issuance_day: null,
        passport_issuance_month: null,
        passport_issuance_year: null,
        passport_expiration_day: null,
        passport_expiration_month: null,
        passport_expiration_year: null,
        national_identification_number: null,
        personal_identification_number: null,
        gender: null,
        has_other_issuing_country_bool: null,
        has_other_issuing_country: [],
        has_other_citizenship_country_bool: null,
        has_other_citizenship_country: [],
        has_other_citizenship_country_before_bool: null,
        has_other_citizenship_country_before: [],
        did_not_acquire_citizenship_bool: null,
        renounced_citizenship_bool: null,
        have_not_lived_birth_country_in_5_years_bool: null,
        have_not_held_passport_in_past_5_years_bool: null,
        other_bool: null,
        other_text: null,
        has_ge_passid_bool: null,
        ge_passid: null,
    },
    has_other_issuing_country: [],
    has_other_citizenship_country: [],
    has_other_citizenship_country_before: [],
    days: ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
    months: [
        {id: '01', desc: 'January'},
        {id: '02', desc: 'February'},
        {id: '03', desc: 'March'},
        {id: '04', desc: 'April'},
        {id: '05', desc: 'May'},
        {id: '06', desc: 'June'},
        {id: '07', desc: 'July'},
        {id: '08', desc: 'August'},
        {id: '09', desc: 'September'},
        {id: '10', desc: 'October'},
        {id: '11', desc: 'November'},
        {id: '12', desc: 'December'},
    ],
    years: [],
    years2: [],
    yearsAll: [],
    countries: [],
    citizenship_countries: [],
    documentTypes1: [
        {val: 'P', description: 'Passport'},
        {val: 'NID', description: 'National Identification Card'}
    ],
    documentTypes2: [
        {val: 'By Birth', description: 'By Birth'},
        {val: 'Through parents', description: 'Through parents'},
        {val: 'Naturalized', description: 'Naturalized'},
        {val: 'Other', description: 'Other'}
    ],
    enable_additional: false,
    }),
    watch: {
    formdata: {
        handler () {
         this.$refs.formRef.resetValidation()
        this.edit();
        },
        deep: true,
      },
    has_other_issuing_country: {
        handler(data) {
            this.formdata.has_other_issuing_country = JSON.parse(JSON.stringify(data))
        },
        deep: true
    },
    has_other_citizenship_country: {
        handler(data) {
            this.formdata.has_other_citizenship_country = JSON.parse(JSON.stringify(data))
        },
        deep: true
    },
    has_other_citizenship_country_before: {
        handler(data) {
            this.formdata.has_other_citizenship_country_before = JSON.parse(JSON.stringify(data))
        },
        deep: true
    },
    clickedNext(val) {
        console.log("clicked-next", val)

        const el = document.querySelector(".error--text");
        if(el){
        this.$vuetify.goTo(".error--text", { offset: 60 })
        }
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    currentStep(val) {
        console.log("current-step", val)
        this.enableAdditional();
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    menu1(val) {
        val && setTimeout(() => (this.activePicker1 = 'YEAR'))
    },
    },
    created() {
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        this.years = range(currentYear, currentYear - 100, -1); 
        this.years2 = range(currentYear, currentYear + 100, 1); 
        let years_all = range(currentYear - 100, currentYear + 100, 1);
        this.yearsAll = years_all.sort(function(a, b) {
    return b - a;
});
    },
    mounted() {
        this.$refs.formRef.resetValidation()
        this.countries = this.$store.state.countries
        this.citizenship_countries = this.$store.state.citizenship_countries
        if(this.$store.state.formdata != null){
        let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata));
        this.formdata = JSON.parse(JSON.stringify(formdata.passport || {}));
        if(formdata.passport){
        this.has_other_issuing_country = JSON.parse(JSON.stringify(formdata.passport.has_other_issuing_country || []));
        this.has_other_citizenship_country = JSON.parse(JSON.stringify(formdata.passport.has_other_citizenship_country || []));
        this.has_other_citizenship_country_before = JSON.parse(JSON.stringify(formdata.passport.has_other_citizenship_country_before || []));
        }
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
        const el = document.querySelector("#stepper-unique-div");
            el.scrollIntoView({
                behavior: 'smooth'
            })
            return;
        }
    },
    methods: {
        save (date) {
            this.$refs.menu1.save(date)
        },
        confirm(){
            if(this.$refs.formRef.validate() && (!this.enable_additional || this.validateAdditionalList(this.formdata.did_not_acquire_citizenship_bool, this.formdata.renounced_citizenship_bool, this.formdata.have_not_lived_birth_country_in_5_years_bool, this.formdata.have_not_held_passport_in_past_5_years_bool, this.formdata.other_bool))){
            let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata || {}));
            formdata.passport = JSON.parse(JSON.stringify(this.formdata));
            this.$store.dispatch("setFormdata", formdata);
            this.$emit("can-continue", {value: true});
            }
            else {
                this.$emit("can-continue", {value: false});
                this.$emit('change-next', {nextBtnValue: false});
                // this.$refs.formRef.resetValidation()
            }
        },
        edit(){
            this.$emit("can-continue", {value: false});
            this.$emit('change-next', {nextBtnValue: false});
            setTimeout(() => {
            document.getElementById("confirm_btn").click();
            }, 0);
        },
        moreNI(choice){
        if(choice && this.has_other_issuing_country.length == 0){
            this.has_other_issuing_country.push({
                has_other_issuing_country_country: null,
                has_other_issuing_country_document_number: null,
                has_other_issuing_country_document_type: null,
                has_other_issuing_country_expiration_year: null,
            })
        }
        else{
            this.has_other_issuing_country = [];
        }
        },
        addNI(){
        this.has_other_issuing_country.push({
            has_other_issuing_country_country: null,
            has_other_issuing_country_document_number: null,
            has_other_issuing_country_document_type: null,
            has_other_issuing_country_expiration_year: null,
        })
        },
        removeNI(index){
            this.has_other_issuing_country.splice(index, 1);
            if(this.has_other_issuing_country.length == 0){
                this.formdata.has_other_issuing_country_bool = false;
            }
        },
        moreOC_now(choice){
        if(choice && this.has_other_citizenship_country.length == 0){
            this.has_other_citizenship_country.push({
                has_other_citizenship_country_country: null,
                has_other_citizenship_country_acquired: null,
            })
        }
        else{
            this.has_other_citizenship_country = [];
        }
        this.enableAdditional();
        },
        addOC_now(){
        this.has_other_citizenship_country.push({
            has_other_citizenship_country_country: null,
            has_other_citizenship_country_acquired: null,
        })
        },
        removeOC_now(index){
            this.has_other_citizenship_country.splice(index, 1);
            if(this.has_other_citizenship_country.length == 0){
                this.formdata.has_other_citizenship_country_bool = false;
            }
        },
        moreOC_been(choice){
        if(choice && this.has_other_citizenship_country_before.length == 0){
            this.has_other_citizenship_country_before.push({
                has_other_citizenship_country_before_country : null,
                has_other_citizenship_country_before_from_day: null,
                has_other_citizenship_country_before_from_month: null,
                has_other_citizenship_country_before_from_year: null,
                has_other_citizenship_country_before_to_day: null,
                has_other_citizenship_country_before_to_month: null,
                has_other_citizenship_country_before_to_year : null
            })
        }
        else{
            this.has_other_citizenship_country_before = [];
        }
        this.enableAdditional();
        },
        addOC_been(){
        this.has_other_citizenship_country_before.push({
            has_other_citizenship_country_before_country : null,
            has_other_citizenship_country_before_from_day: null,
            has_other_citizenship_country_before_from_month: null,
            has_other_citizenship_country_before_from_year: null,
            has_other_citizenship_country_before_to_day: null,
            has_other_citizenship_country_before_to_month: null,
            has_other_citizenship_country_before_to_year : null
        })
        },
        removeOC_been(index){
            this.has_other_citizenship_country_before.splice(index, 1);
            if(this.has_other_citizenship_country_before.length == 0){
                this.formdata.has_other_citizenship_country_before_bool = false;
            }
        },
        changeCitizenship(){
            this.formdata.issuing_country = this.formdata.country_of_citizenship
        },
        enableNationalId(){
            let countries = this.$store.state.citizenship_to_national_id;
            let result = countries.includes(this.formdata.country_of_citizenship)
            if(!result){
                this.formdata.national_identification_number = null
            }
            return !result;
        },
        enablePersonalId(){
            let countries = this.$store.state.citizenship_to_personal_id;
            let result = countries.includes(this.formdata.country_of_citizenship)
            if(!result){
                this.formdata.personal_identification_number = null
            }
            return !result;
        },
        reset(){
            this.formdata = {
                passport: {
                    nationality: null,
                    territory: null,
                    number: null,
                    expiry_date: null,
                },
                residency_questions: [],
                eligibility_questions: [],
            };
            this.getSeparatedQuestions();
            this.$refs.formRef.resetValidation()
        },
        enableAdditional(){
            let country = this.$store.state.forbidden_countries;
            let birth_country = this.$store.state.formdata.applicant.birth_country;
            if(country.includes(birth_country) && this.formdata.has_other_citizenship_country_bool != null){
                this.enable_additional = true;
            }
            else{
                this.enable_additional = false
            }
        },
    },
  }
</script>
<style scoped>
.asterisk{
    color: #ed527c;
    font-size: 20px;
    margin-left: 7px;
}
.other_names_style{
    background-color: #f2f2f2;
    border-radius: 4px;
}
.date-error {
    color: #ff5959 !important;
    font-size: 12px;
    padding-left: 10px;
    word-break: break-word;
    word-wrap: break-word;
    line-height: 12px;
    hyphens: auto;
  }
.list-error {
    color: #ff5959 !important;
    font-size: 12px;
    padding-left: 10px;
    word-break: break-word;
    word-wrap: break-word;
    line-height: 12px;
    hyphens: auto;
}
</style>