<template>
<div class="pa-10 div-container">
    <v-form @submit.prevent ref="formRef">
  <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">General Information</div></v-col>
    <!-- <v-col cols="12"><v-divider></v-divider></v-col> -->
    <v-col cols="12" md="8" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Email Address<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.email"
        color="grey"
        :rules="[formRules.required, formRules.email]"
        height="45"
        outlined
        hide-details="auto"
        ></v-text-field>
        <div class="hint-text">
            Please, add a personal email address that you use frequently. Please make sure you enter a valid email address. We will use it to contact you about your application.
        </div>
    </v-col>
    <v-col cols="12" class="pt-3"><v-divider></v-divider></v-col>
  </v-row>
  <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Applicant Information</div></v-col>
    <!-- <v-col cols="12"><v-divider></v-divider></v-col> -->
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">First (Given) Names(s)<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.first_given_names"
        color="grey"
        :rules="[formRules.required, formRules.englishOnly]"
        height="45"
        outlined
        hide-details="auto"
        ></v-text-field>
        <div class="hint-text">
            Please make sure the first name(s) are exactly as shown on your passport or travel document.
        </div>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Family Name(s)<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.family_names"
        color="grey"
        :rules="[formRules.required, formRules.englishOnly]"
        height="45"
        outlined
        hide-details="auto"
        ></v-text-field>
        <div class="hint-text">
            Please make sure the family name(s) are exactly as shown on your passport or travel document.
        </div>
    </v-col>
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Are you known by any other names or aliases?</div>
        <v-radio-group
            v-model="formdata.previous_names_bool"
            @change="moreNames(formdata.previous_names_bool)"
            :rules="[formRules.required3]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
            </v-radio-group>
    </v-col>
    <v-col cols="12" class="previous_names_style" v-if="previous_names.length > 0">
        <v-row no-gutters v-for="(name, index) in previous_names" :key="index">
            <v-col cols="12" class="pa-0 other-toolber">
                <v-toolbar flat max-height="50" class="pa-0 ma-0" color="transparent">
                <span class="text-h6">Other Name {{index + 1}}</span>
                <v-spacer></v-spacer>
                <v-btn icon color="#00a34e" @click="removeName(index)"><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-toolbar>
            </v-col>
            <v-col cols="12" class="pt-0 mb-4"><v-divider></v-divider></v-col>
            <v-col cols="12" md="6" class="px-2">
                <div class="px-0 pb-2 text-subtitle-1">First (Given) Name(s)<span class="asterisk">*</span></div>
                <v-text-field
                v-model="name.first_given_names"
                :rules="[formRules.required, formRules.englishOnly]"
                color="grey"
                height="45"
                outlined
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="px-2">
                <div class="px-0 pb-2 text-subtitle-1">Family Name(s)<span class="asterisk">*</span></div>
                <v-text-field
                v-model="name.family_names"
                :rules="[formRules.required, formRules.englishOnly]"
                color="grey"
                height="45"
                outlined
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
        <v-col cols="12" class="pb-8">
            <v-btn color="#00a34e" dark class="elevation-0 rounded-md" @click="addName()">Add Another Name</v-btn>
        </v-col>
        </v-row> 
    </v-col>
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-5 text-subtitle-1">Date of Birth<span class="asterisk">*</span></div>
        <v-row>
        <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
                v-model="formdata.dob_day"
                color="grey"
                :rules="[formRules.required, !invalidDate(formdata.dob_day, formdata.dob_month,formdata.dob_year)]"
                height="45"
                outlined
                placeholder="Day"
                :items="days"
                item-color="#00a34e"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
            <v-autocomplete
                v-model="formdata.dob_month"
                color="grey"
                :rules="[formRules.required, !invalidDate(formdata.dob_day, formdata.dob_month,formdata.dob_year)]"
                height="45"
                outlined
                placeholder="Month"
                item-text="desc"
                item-value="id"
                :items="months"
                item-color="#00a34e"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
                v-model="formdata.dob_year"
                color="grey"
                :rules="[formRules.required, !invalidDate(formdata.dob_day, formdata.dob_month,formdata.dob_year)]"
                height="45"
                outlined
                placeholder="Year"
                :items="years"
                item-color="#00a34e"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="pt-0 mt-n7" v-if="invalidDate(formdata.dob_day, formdata.dob_month,formdata.dob_year)">
            <span class="date-error">Date of birth is invalid!</span>
        </v-col>
        </v-row>
    </v-col>
    <v-col cols="12" md="3" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Country of Birth<span class="asterisk">*</span></div>
        <v-autocomplete
            v-model="formdata.birth_country"
            color="grey"
            :rules="[formRules.required]"
            height="45"
            outlined
            item-text="country"
            item-value="code"
            :items="countries"
            item-color="#00a34e"
        ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="3" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">City of Birth<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.birth_city"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        outlined
        hide-details="auto"
        ></v-text-field>
        <div class="hint-text">
            If you can't find this listed on your passport, enter the name of the city/town/village where you were born.
        </div>
    </v-col>
    <v-col cols="12" class="pt-3"><v-divider></v-divider></v-col>
  </v-row>
  <v-row class="justify-center mt-10" v-show="false">
  <v-btn @click="confirm()" id="confirm_btn" color="grey darken-2" class="mx-1 rounded-0 elevation-0 white--text">save Details</v-btn>
  </v-row>
  </v-form>
</div>
</template>
<script>
  export default {
    props: ['clickedNext', 'currentStep'],
    data: () => ({
    formdata: {
        email: null,
        first_given_names: null,
        family_names: null,
        previous_names_bool: null,
        previous_names: [],
        dob_day: null,
        dob_month: null,
        dob_year: null,
        birth_country: null,
        birth_city: null,
    },
    previous_names: [],
    days: ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
    months: [
        {id: '01', desc: 'January'},
        {id: '02', desc: 'February'},
        {id: '03', desc: 'March'},
        {id: '04', desc: 'April'},
        {id: '05', desc: 'May'},
        {id: '06', desc: 'June'},
        {id: '07', desc: 'July'},
        {id: '08', desc: 'August'},
        {id: '09', desc: 'September'},
        {id: '10', desc: 'October'},
        {id: '11', desc: 'November'},
        {id: '12', desc: 'December'},
    ],
    years: [],
    countries: []
    }),
    watch: {
    formdata: {
        handler () {
         this.$refs.formRef.resetValidation()
        this.edit();
        },
        deep: true,
      },
      previous_names: {
        handler(data) {
            this.formdata.previous_names = JSON.parse(JSON.stringify(data))
        },
        deep: true
    },
    clickedNext(val) {
        console.log("clicked-next", val)

        const el = document.querySelector(".error--text");
        if(el){
        this.$vuetify.goTo(".error--text", { offset: 60 })
        }
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    currentStep(val) {
        console.log("current-step", val)
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    menu1(val) {
        val && setTimeout(() => (this.activePicker1 = 'YEAR'))
    },
    },
    created() {
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        this.years = range(currentYear, currentYear - 100, -1); 
    },
    mounted() {
        setTimeout(() => {
        this.$refs.formRef.resetValidation()
        }, 600);
        this.countries = this.$store.state.countries;
        if(this.$store.state.formdata != null){
        let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata));
        this.formdata = JSON.parse(JSON.stringify(formdata.applicant || {}));
        this.previous_names = JSON.parse(JSON.stringify(formdata.applicant.previous_names || []));
        this.previous_names.forEach(item => {
            item.first_given_names = this.decodeName(item.first_given_names)
            item.family_names = this.decodeName(item.family_names)
        });
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
        const el = document.querySelector("#stepper-unique-div");
            el.scrollIntoView({
                behavior: 'smooth'
            })
            return;
        }
    },
    methods: {
        save (date) {
            this.$refs.menu1.save(date)
        },
        confirm(){
            if(this.$refs.formRef.validate()){
            let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata || {}));
            formdata.applicant = JSON.parse(JSON.stringify(this.formdata));
            this.$store.dispatch("setFormdata", formdata);
            this.$emit("can-continue", {value: true});
            }
            else {
                this.$emit("can-continue", {value: false});
                this.$emit('change-next', {nextBtnValue: false});
            }
        },
        edit(){
            this.$emit("can-continue", {value: false});
            this.$emit('change-next', {nextBtnValue: false});
            setTimeout(() => {
            document.getElementById("confirm_btn").click();
            }, 0);
        },
        moreNames(choice){
        if(choice && this.previous_names.length == 0){
            this.previous_names.push({
                family_names: null,
                first_given_names: null
            })
        }
        else{
            this.previous_names = [];
        }
        },
        addName(){
        this.previous_names.push({
            family_names: null,
            first_given_names: null
        })
        },
        removeName(index){
            this.previous_names.splice(index, 1);
            if(this.previous_names.length == 0){
                this.formdata.previous_names_bool = false;
            }
        },
    },
  }
</script>
<style scoped>
.asterisk{
    color: #ed527c;
    font-size: 20px;
    margin-left: 7px;
}
.previous_names_style{
    background-color: #f2f2f2;
    border-radius: 4px;
}
.date-error {
    color: #ff5959 !important;
    font-size: 12px;
    padding-left: 10px;
    word-break: break-word;
    word-wrap: break-word;
    line-height: 12px;
    hyphens: auto;
  }
</style>