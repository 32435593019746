<template>
<div class="pa-10 div-container">
    <v-form @submit.prevent ref="formRef">
  <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Additional Travel Information Required</div></v-col>
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Is your travel to the US occurring to transit to another country?</div>
        <v-radio-group
            v-model="formdata.transit_bool"
            :rules="[formRules.required3]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="pt-3"><v-divider></v-divider></v-col>
  </v-row>
  <div v-if="formdata.transit_bool == false">
    <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">U.S. Point Of Contact Information</div></v-col>
    <v-col cols="12" class="py-0">
        <v-switch
        v-model="formdata.us_contact_unknown_bool"
        inset
        label="Unknown"
        color="#00a34e"
        ></v-switch>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.us_contact_unknown_bool">
        <v-alert
        type="error"
        dense
        >
        Selecting Unknown US Contact may lower your chance of application success, please enter a person, or business hotel if possible.
    </v-alert>
    </v-col>
    <v-col cols="12" class="py-0">
        <v-alert
        text
        type="info"
        dense
        >
        Enter the person or business you will be visiting in the United States. This can be the address of your hotel or lodging. If you do not yet have your final travel plans, you may select 'Unknown' to skip these fields, but it is advised that you fill it in to raise the chances of a successful application.
    </v-alert>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Name</div>
        <v-text-field
        v-if="formdata.us_contact_unknown_bool"
        color="grey"
        height="45"
        placeholder="Name"
        outlined
        disabled
        hide-details="auto"
        ></v-text-field>
        <v-text-field
        v-else
        v-model="formdata.us_contact_name"
        color="grey"
        :rules="[formRules.required, formRules.englishOnly]"
        height="45"
        placeholder="Name"
        outlined
        hide-details="auto"
        ></v-text-field>
        <div class="hint-text">
            If you do not personally know anyone in the US, you may enter the name of the hotel, store, company, organization, or University you plan to visit during your trip.
        </div>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Address Line 1<span class="asterisk">*</span></div>
        <v-text-field
        v-if="formdata.us_contact_unknown_bool"
        color="grey"
        height="45"
        placeholder="Address Line 1"
        outlined
        disabled
        ></v-text-field>
        <v-text-field
        v-else
        v-model="formdata.us_contact_address_line_1"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Address Line 1"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Address Line 2</div>
        <v-text-field
        v-if="formdata.us_contact_unknown_bool"
        color="grey"
        height="45"
        placeholder="Address Line 2"
        outlined
        disabled
        ></v-text-field>
        <v-text-field
        v-else
        v-model="formdata.us_contact_address_line_2"
        color="grey"
        :rules="[formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Address Line 2"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Apartment Number</div>
        <v-text-field
        v-if="formdata.us_contact_unknown_bool"
        color="grey"
        height="45"
        placeholder="Apartment Number"
        outlined
        disabled
        ></v-text-field>
        <v-text-field
        v-else
        v-model="formdata.us_contact_apartment_number"
        color="grey"
        :rules="[formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Apartment Number"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">City<span class="asterisk">*</span></div>
        <v-text-field
        v-if="formdata.us_contact_unknown_bool"
        color="grey"
        height="45"
        placeholder="City"
        outlined
        disabled
        ></v-text-field>
        <v-text-field
        v-else
        v-model="formdata.us_contact_city"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        placeholder="City"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">State/Province/Region<span class="asterisk">*</span></div>
        <v-autocomplete
            v-if="formdata.us_contact_unknown_bool"
            color="grey"
            height="45"
            outlined
            item-text="state"
            item-value="code"
            :items="states"
            item-color="#00a34e"
            placeholder="State/Province/Region"
            disabled
        ></v-autocomplete>
        <v-autocomplete
            v-else
            v-model="formdata.us_contact_state"
            @change="changeState()"
            color="grey"
            :rules="[formRules.required]"
            height="45"
            outlined
            item-text="state"
            item-value="code"
            :items="states"
            item-color="#00a34e"
            placeholder="State/Province/Region"
        ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Country Code + Phone<span class="asterisk">*</span></div>
        <span class="phone-style">
        <vue-tel-input-vuetify
          :valid-characters-only="true"
          v-model="formdata.us_contact_phone_number"
          label=""
          color="grey"
          :rules="[formRules.required, valid_ucpn || 'Invalid phone number']"
          height="45"
          outlined
          type="tel"
          placeholder="Enter your phone number"
          :onlyCountries="$store.state.state_to_phone"
          @input="onInputUCPN"
        />
        </span>
    </v-col>
    </v-row>
    <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Address While in the U.S.</div></v-col>
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Use the same address as above?</div>
        <v-radio-group
            v-model="formdata.us_address_same_as_us_contact_bool"
            :rules="[formRules.required3]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" md="4" class="py-0" v-if="formdata.us_address_same_as_us_contact_bool == false">
        <div class="px-0 pb-2 text-subtitle-1">Address Line 1<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.us_address_line_1"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Address Line 1"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0" v-if="formdata.us_address_same_as_us_contact_bool == false">
        <div class="px-0 pb-2 text-subtitle-1">Address Line 2</div>
        <v-text-field
        v-model="formdata.us_address_line_2"
        color="grey"
        :rules="[formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Address Line 2"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0" v-if="formdata.us_address_same_as_us_contact_bool == false">
        <div class="px-0 pb-2 text-subtitle-1">Apartment Number</div>
        <v-text-field
        v-model="formdata.us_apartment_number"
        color="grey"
        :rules="[formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Apartment Number"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0" v-if="formdata.us_address_same_as_us_contact_bool == false">
        <div class="px-0 pb-2 text-subtitle-1">City<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.us_city"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        placeholder="City"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0" v-if="formdata.us_address_same_as_us_contact_bool == false">
        <div class="px-0 pb-2 text-subtitle-1">State/Province/Region<span class="asterisk">*</span></div>
        <v-autocomplete
            v-model="formdata.us_state"
            color="grey"
            :rules="[formRules.required]"
            height="45"
            outlined
            item-text="state"
            item-value="code"
            :items="states"
            item-color="#00a34e"
            placeholder="State/Province/Region"
        ></v-autocomplete>
    </v-col>
    </v-row>
  </div>
    <v-row>
    <v-col cols="12">
        <v-alert outlined color="#00b055" class="agoda-style" @click="goToAgoda()">
            <v-row align="center">
            <v-col class="shrink"><v-img src="../../assets/img/agoda-logo.svg" width="80"></v-img></v-col>
            <v-col>
            <div><strong>Haven't booked your stay yet?</strong></div>
            <div>We recommend Agoda for the best value and incredible discounts!</div>
            </v-col>
            <!-- <v-col cols="12" md="3" align="end">
            <v-spacer></v-spacer>
            <v-btn outlined color="white">Book Your Stay Today</v-btn>
            </v-col> -->
            </v-row>
        </v-alert>
    </v-col>
    </v-row>
  <v-row class="justify-center mt-10">
  <v-btn :loading="isSubmitting" color="#00a34e" class="elevation-0 ml-1 pa-5" dark @click="submitInfo()">
                proceed to passport upload
                <v-icon color="white">mdi-arrow-right</v-icon>
            </v-btn>
  </v-row>
  </v-form>
  <LoadingScreen v-show="isSubmitting" />
</div>
</template>
<script>
import LoadingScreen from '../../components/Utils/LoadingScreen2.vue';
  export default {
    components: {
        LoadingScreen
    },
    data: () => ({
    formdata: {
        transit_bool: null,
        us_contact_unknown_bool: null,
        us_contact_name: null,
        us_contact_address_line_1: null,
        us_contact_address_line_2: null,
        us_contact_apartment_number: null,
        us_contact_city: null,
        us_contact_state: null,
        us_contact_phone_number: null,
        us_address_same_as_us_contact_bool: null,
        us_address_line_1: null,
        us_address_line_2: null,
        us_apartment_number: null,
        us_city: null,
        us_state: null,
    },
    states: [],
    valid_ucpn: false,
    valid_ecpn: false,
    formatted_ucpn: null,
    formatted_ecpn: null,
    isSubmitting: false
    }),
    watch: {
    formdata: {
        handler () {
        },
        deep: true,
      },
    menu1(val) {
        val && setTimeout(() => (this.activePicker1 = 'YEAR'))
    },
    },
    created() {
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        this.years = range(currentYear, currentYear - 100, -1); 
    },
    mounted() {
        window._gs('event', 'Additional Information Page');
        this.states = this.$store.state.states
        if(this.$store.state.formdata != null && this.$store.state.formdata.travel){
        let data = JSON.parse(JSON.stringify(this.$store.state.formdata.travel));
        this.formdata = {
        transit_bool: data.transit_bool,
        us_contact_unknown_bool: data.us_contact_unknown_bool,
        us_contact_name: data.us_contact_name,
        us_contact_address_line_1: data.us_contact_address_line_1,
        us_contact_address_line_2: data.us_contact_address_line_2,
        us_contact_apartment_number: data.us_contact_apartment_number,
        us_contact_city: data.us_contact_city,
        us_contact_state: data.us_contact_state,
        us_contact_phone_number: data.us_contact_phone_number,
        us_address_same_as_us_contact_bool: data.us_address_same_as_us_contact_bool,
        us_address_line_1: data.us_address_line_1,
        us_address_line_2: data.us_address_line_2,
        us_apartment_number: data.us_apartment_number,
        us_city: data.us_city,
        us_state: data.us_state,
        }
        }

    },
    methods: {
        save (date) {
            this.$refs.menu1.save(date)
        },
        submitInfo(){
            if(this.$refs.formRef.validate()){
            this.isSubmitting = true;
            let data = JSON.parse(JSON.stringify(this.formdata))
            data.us_contact_phone_number = this.formatted_ucpn;
            let formdata = {application_token: this.$store.state.token}
            formdata.transit_bool = data.transit_bool;
            formdata.us_contact_unknown_bool = data.us_contact_unknown_bool;
            formdata.us_contact_name = data.us_contact_name || '';
            formdata.us_contact_address_line_1 = data.us_contact_address_line_1 || '';
            formdata.us_contact_address_line_2 = data.us_contact_address_line_2 || '';
            formdata.us_contact_apartment_number = data.us_contact_apartment_number || '';
            formdata.us_contact_city = data.us_contact_city || '',
            formdata.us_contact_state = data.us_contact_state || '';
            formdata.us_contact_phone_number = data.us_contact_phone_number || '';
            formdata.us_address_same_as_us_contact_bool = data.us_address_same_as_us_contact_bool;
            formdata.us_address_line_1 = data.us_address_line_1 || '';
            formdata.us_address_line_2 = data.us_address_line_2 || '';
            formdata.us_apartment_number = data.us_apartment_number || '';
            formdata.us_city = data.us_city || '';
            formdata.us_state = data.us_state || '';
            console.log(formdata)
            const formDataBody = new FormData();
            for (const [key, value] of Object.entries(formdata)) {
                formDataBody.append(key, value);
            }
            this.axiosCall(this.apiUrl2 + "/esta/details/", "POST", formDataBody)
            .then((res) => {
                if (res.data.type == 'success') {
                        this.$router.push('/docupass/' + this.$store.state.token + '/')
                        this.$router.go(0);
                  }
                // }
            })
            .catch((error) => {
                this.isSubmitting = false;
                console.log(error);
            });
            }
        },
        changeState(){
            if(this.formdata.us_contact_phone_number == null){
            this.formdata.us_contact_phone_number = '+1';
            }
        },
        onInputUCPN(formattedNumber, { number, valid, country }) {
            console.log(formattedNumber, number, valid, country)
            this.valid_ucpn = valid
            this.formatted_ucpn = number.e164
        },
        onInputECPN(formattedNumber, { number, valid, country }) {
            console.log(formattedNumber, number, valid, country)
            this.valid_ecpn = valid
            this.formatted_ecpn = number.e164
        },
    },
  }
</script>
<style scoped>
.asterisk{
    color: #ed527c;
    font-size: 20px;
    margin-left: 7px;
}
.other_names_style{
    background-color: #f2f2f2;
    border-radius: 4px;
}
</style>