<template>
  <div>
    <div class="outer-content">
    <!-- <div class="bg-content"></div> -->
      <div class="inner-content">
       <v-toolbar elevation="0" class="pa-2" color="transparent" max-height="20">
        <v-img src="../../assets/img/usa-esta-logo.svg" class="logo-style" max-width="160"></v-img>
        <v-spacer></v-spacer>
        <Translation />
       </v-toolbar>
    </div>
  </div>
  <div class="content">
    <section class="section">
        <div class="container">
            <div id="legal-content" class="pt-5"><h1>Disclaimers</h1> <div style="text-align: justify;"><p><strong>{{G_DOMAIN}} ({{G_WEB_TITLE}})</strong> is a commercial website. We are not a competent office of any Government or representative of any Government Embassies, Consulates, or Missions. We are not a law firm, and this website does not offer legal advice, explanations, opinions, or recommendations regarding legal rights.</p> <p>If you apply through <strong>{{G_DOMAIN}}</strong>, we will assist with the preparation and submission of all required documents for your travel documents. You will receive updates on your visa status and results from us frequently.</p> <p>To apply for an eVisa through our processing service, you will be charged both a Government Fee and a Service Fee. Therefore, our fee will be higher than the fee you would pay directly on the Government Website or at Missions in your country.</p></div></div>
        </div>
    </section>
    <Footer />
  </div>
  </div>
  
</template>

<script>
import Translation from '../../components/Utils/Translation.vue';
import Footer from '../../components/Footer/Footer.vue';
export default {
  name: "PaymentThankYou",
  components: {
    Translation,
    Footer,
  },
  data: () => ({
    
  }),
  methods: {
    startNewApplication(){
      this.$router.push("/");
      this.$router.go();
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.outer-content {
  background-color: #ffffff !important;
}
.bg-content {
  /* background: url("../../assets/img/background.png"); */
   position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.inner-content{
    background-color: #ffffff !important;
    padding: 0 13%;
}
.content{
    margin: 30px 15%;
}
.container{
    text-align: justify;
}
h1 {
    font-size: 38px;
}
h2 {
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: 28px;
}
h5 {
    font-size: 20px;
    padding-top: 15px;
}
@media only screen and (max-width: 600px) {
    .inner-content{
        padding: 0 3%;
    }
    .content{
        margin: 80px 0;
    }
}
</style>
