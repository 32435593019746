import { render, staticRenderFns } from "./CookiePolicy.vue?vue&type=template&id=794299c2&scoped=true&"
import script from "./CookiePolicy.vue?vue&type=script&lang=js&"
export * from "./CookiePolicy.vue?vue&type=script&lang=js&"
import style0 from "./CookiePolicy.vue?vue&type=style&index=0&id=794299c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "794299c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VImg,VSpacer,VToolbar})
