<template>
<div class="pa-10 footer-container">
    <p class="mt-16 mb-10">Copyright © 2024 — {{G_WEB_TITLE}}. All rights reserved.</p>
    <p class="disclaimer">
        <strong>Legal Disclaimer:</strong> <u>{{G_SUBDOMAIN}}</u> was developed to provide Agency Services to visitors from all over the world and give information about immigration topics. <u>{{G_SUBDOMAIN}}</u> is owned and operated by a private agency that is not affiliated and/or enforced by the United States Department of State (US DOS), the United States Department of Homeland Security (US DHS), the United States Citizenship & Immigration Services (USCIS), or any other United States governmental agency. Your access to the website is subject to our Terms of Use & Conditions. Communications between you and <u>{{G_SUBDOMAIN}}</u> are protected by our Privacy Policy but not by the attorney-client privilege or as work product. Listed purchase prices for application preparation assistance services do NOT include any government application, medical examination fee, filing, or biometrics fees, which will be charged by the U.S Government and are never refundable. Neither <u>{{G_SUBDOMAIN}}</u> nor its employees claim to have any special knowledge of immigration law or procedure. Only licensed immigration professionals can provide advice, explanation, opinion, or recommendation about possible legal rights, remedies, defenses, options, selection of forms or strategies. You may apply by yourself directly at <a href="https://travel.state.gov/content/travel.html" target="_blank">travel.state.gov</a> or at <a href="https://www.uscis.gov/" target="_blank">uscis.gov</a>.
    </p>
    <v-row justify="center" align-items="center" class="my-5">
        <v-img max-width="100" src="../../assets/img/ssl.png"></v-img>
        <v-img max-width="200" max-height="40" class="mt-2" src="../../assets/img/dmca-badge.png"></v-img>
    </v-row>
    <div class="mt-8 terms-and-policy">
        <span @click="goToTerms">Terms of Use & Conditions</span>  |  <span @click="goToPolicy">Privacy Policy</span>  |  <span @click="goToCookie">Cookie Policy</span>  |  <span @click="goToDisclaimers">Disclaimers</span>  |  <span @click="goToDSA">Digital Services Act</span>
    </div>
</div>
</template>
<script>
  export default {
    data: () => ({

    }),
    mounted() {},
    methods: {
        goToTerms(){
            window.open(this.apiUrl1 + "/terms-of-use/", "_blank");
        },
        goToPolicy(){
            window.open(this.apiUrl1 + "/privacy-policy/", "_blank");
        },
        goToCookie(){
            window.open(this.apiUrl1 + "/cookie-policy/", "_blank");
        },
        goToDisclaimers(){
            window.open(this.apiUrl1 + "/disclaimers/", "_blank");
        },
        goToDSA(){
            window.open(this.apiUrl1 + "/digital-services-act/", "_blank");
        }
    },
  }
</script>
<style scoped>
.footer-container {
    text-align: center;
    margin-top: 130px;
    color: rgb(158, 157, 157);
}
.disclaimer {
    font-size: 14px !important;
}
.disclaimer a {
    color: rgb(158, 157, 157);
}
.terms-and-policy {
    font-size: 14px !important;
}
.terms-and-policy span {
    color: rgb(158, 157, 157);
    text-decoration: underline;
    cursor: pointer;
}
</style>