<template>
  <div>
    <div class="outer-content">
    <!-- <div class="bg-content"></div> -->
      <div class="inner-content">
       <v-toolbar elevation="0" class="pa-2" color="transparent" max-height="20">
        <v-img src="../../assets/img/usa-esta-logo.svg" class="logo-style" max-width="160"></v-img>
        <v-spacer></v-spacer>
        <Translation />
       </v-toolbar>
    </div>
  </div>
  <div class="content">
    <section class="section">
        <div class="container">
          <OtherInfo />
        </div>
    </section>
    <Footer />
  </div>
  </div>
  
</template>

<script>
import Translation from '../../components/Utils/Translation.vue';
import Footer from '../../components/Footer/Footer.vue';
import OtherInfo from '../../components/Forms/OtherInfo.vue';
export default {
  name: "AdditionalInfo",
  components: {
    Translation,
    Footer,
    OtherInfo,
  },
  data: () => ({
    
  }),
  methods: {
    
  },
  mounted() {
  },
};
</script>

<style scoped>
.outer-content {
  background-color: #ffffff !important;
}
.bg-content {
  /* background: url("../../assets/img/background.png"); */
   position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.inner-content{
    background-color: #ffffff !important;
    padding: 0 13%;
}
.content{
    margin: 30px 15%;
}
.container{
    text-align: justify;
}
h1 {
    font-size: 38px;
}
h2 {
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: 28px;
}
h5 {
    font-size: 20px;
    padding-top: 15px;
}
@media only screen and (max-width: 600px) {
    .inner-content{
        padding: 0 3%;
    }
    .content{
        margin: 80px 0;
    }
}
</style>
